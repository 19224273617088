import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { DOMAINS, LOCALES } from '../types/constants';
import { Microdata } from '../types/types';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public menuItems: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );
  public menuEnableLinks: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public footerItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public microdata: BehaviorSubject<Microdata> = new BehaviorSubject<Microdata>(null);

  private _domain: string;
  private _locale: string;
  private _lang: string;
  private _lastActivePageIsMain: boolean = true;

  constructor(private _meta: Meta, private _titleService: Title) {}

  public setLastActivePageIsMain(value: boolean): void {
    this._lastActivePageIsMain = value;
  }

  public getLastActivePageIsMain(): boolean {
    return this._lastActivePageIsMain;
  }

  public setMenuItems(items: []): void {
    this.menuItems.next(items);
  }

  public setMenuLinks(state: boolean): void {
    this.menuEnableLinks.next(state);
  }

  public setFooterLinks(footer): void {
    this.footerItems.next(footer);
  }

  public setMicrodata(contacts: Microdata): void {
    this.microdata.next(contacts);
  }

  public setLang(): void {
    switch (this._locale) {
      case LOCALES.EN:
        this._lang = this._locale;
        break;
      default:
        this._lang = LOCALES.RU;
        break;
    }
  }

  public setLocale(domain: string): void {
    this._domain = domain;
    const domainCode: string = domain.split('.')[1];

    switch (domainCode) {
      case DOMAINS.RU:
        this._locale = LOCALES.RU;
        break;
      case DOMAINS.EN:
        this._locale = LOCALES.EN;
        break;
      case DOMAINS.KZ:
        this._locale = LOCALES.KZ;
        break;
    }
  }

  public getLocale(): string {
    return this._locale;
  }

  public getDomain(): string {
    return this._domain;
  }

  public getLang(): string {
    return this._lang;
  }

  public setMeta(meta): void {
    if (!meta) {
      return;
    }

    this.setPageTitle(meta.title);
    this._meta.removeTag("name='description'");
    this._meta.removeTag("name='keywords'");
    this._meta.addTag({ name: 'description', content: meta.description }, true);
    this._meta.addTag({ name: 'keywords', content: meta.keywoards }, true);
  }

  public setPageTitle(title: string | null): void {
    this._meta.removeTag("name='title'");
    this._titleService.setTitle(title || 'Custom booking system');
    this._meta.addTag({ name: 'title', content: title }, true);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { StateService } from '../../../services/state-service.service';
import settings from '../../../../settings';
import { Microdata } from 'src/app/types/types';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [],
})
export class FooterComponent implements OnInit, OnDestroy {
  public footerText: string = '';
  public footerItems: any[] = [];
  public microdata: Microdata = {
    name: '',
    telephone: '',
    email: '',
    address: { streetAddress: '', addressLocality: ''}
  };

  private _url: string = settings.apiUrl;
  private _subscriptions: Array<Subscription> = [];

  constructor(private _stateService: StateService) {}

  // todo: add types
  ngOnInit(): void {
    this._subscriptions.push(
      this._stateService.footerItems.subscribe((footer) => {
        this.footerText = footer?.Footer_text;
        const companyName = footer?.Footer_text.match(/23\s?[a-zа-я]+/)[0];
        this.microdata.name = companyName ? companyName : '';

        footer?.footer_Link.forEach((item) => {
          if (item?.link && item?.image?.data?.attributes) {
            this.footerItems.push({
              link: item.link,
              image: this._url + item.image.data.attributes.url,
              width: item.image.data.attributes.width,
              height: item.image.data.attributes.height,
            });
          }
        });
      }),
      this._stateService.microdata.subscribe((microdata) => {
        this.microdata = {
          ...this.microdata,
          ...microdata
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

<footer class="container" itemscope itemtype="http://schema.org/Organization">
  <p class="copyright">{{ footerText }}</p>
  <meta itemprop="name" [content]="microdata.name">

  <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
    <meta itemprop="addressLocality" [content]="microdata.address.addressLocality">
    <meta itemprop="streetAddress" [content]="microdata.address.streetAddress">
    <meta itemprop="telephone" [content]="microdata.telephone">
    <meta itemprop="email" [content]="microdata.email">
  </div>
  
  <p class="icons">
    @for (item of footerItems; track $index) {
      <a class="link" target="_blank" rel="noopener" href="{{ item.link }}">
        <img
          loading="lazy"
          decoding="async"
          [src]="item.image"
          [width]="item.width"
          [height]="item.height"
          alt="logo"
        />
      </a>
    }
  </p>
</footer>
